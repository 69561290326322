<script>

import { menu } from '../router'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  setup() {
    const store = useStore()
    const router = useRouter()
    router.push(menu[store.state.account.role][0].to)
  }
}
</script>

<style lang="scss" module>
</style>