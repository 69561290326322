<template>
  <h1>Доступ к разделу запрещен</h1>
</template>

<script>

import { ref } from 'vue';

export default {
  components: {},
  setup() {

    return {}
  }
}
</script>

<style lang="scss" module>
</style>